import { createStore } from 'vuex';
import axios from 'axios';
// eslint-disable-next-line import/no-unresolved, import/extensions
import User from '@/types/User';
// eslint-disable-next-line import/no-unresolved, import/extensions
import Ecriture from '@/types/Ecriture';
// eslint-disable-next-line import/no-unresolved, import/extensions
import Compte from '@/types/Compte';
// eslint-disable-next-line import/no-unresolved, import/extensions
import EcritureTVA from '@/types/EcritureTVA';
// eslint-disable-next-line import/no-unresolved, import/extensions
import Fichier from '@/types/Fichier';
// eslint-disable-next-line import/no-unresolved, import/extensions
import TypeFichier from '@/types/TypeFichier';
// eslint-disable-next-line import/no-unresolved, import/extensions
import ExerciceComptable from '@/types/ExerciceComptable';
import {
  getStandaloneFichiers,
// eslint-disable-next-line import/no-unresolved, import/extensions
} from '@/api/api';

export default createStore({
  state: {
    user: {
      ID: 0,
      token: '',
      username: '',
      expiration: new Date(),
      admin: false,
    } as User,
    data: {
      comptes: new Array<Compte>(),
      ecritures: new Array<Ecriture>(),
      typesFichier: new Array<TypeFichier>(),
      fichiers: new Array<Fichier>(),
      standaloneFiles: new Array<Fichier>(),
      comptabilites: [] as any[],
      exercicesComptable: new Array<ExerciceComptable>(),
      bilan: [] as any[],
    },
    all: {
      loading: false,
      backgroundLoading: false,
      messages: null,
      selectedComptabiliteId: null,
      selectedExerciceComptableId: null,
    },
    app: {
      version: process.env.VUE_APP_VERSION || '0',
    },
  },
  getters: {
    appVersion: (state) => state.app.version,
    token: (state) => state.user.token,
    logged: (state) => state.user.token !== null && state.user.token.length > 0,
    user: (state) => state.user,
    comptes: (state) => state.data.comptes,
    comptesComptable: (state) => state.data.comptes.filter((cpt) => cpt.id >= 1000),
    getCompteById:
     (state) => (id:number) => state.data.comptes.find((cpt) => cpt.id === id),
    ecritures: (state) => state.data.ecritures,
    getEcritureById:
     (state) => (id:number) => state.data.ecritures.find((ecr) => ecr.ID === id),
    comptabilites: (state) => state.data.comptabilites,
    getComptabiliteById:
      (state) => (id:number) => state.data.comptabilites.find((com) => com.id === id),
    typesFichier: (state) => state.data.typesFichier,
    getTypesFichierById:
      (state) => (id:number) => state.data.typesFichier.find((com) => com.id === id),
    fichiers: (state) => state.data.fichiers,
    getFichiersById:
      (state) => (id:number) => state.data.fichiers.find((com) => com.id === id),
    standaloneFiles: (state) => state.data.standaloneFiles,
    selectedComptabiliteId: (state) => state.all.selectedComptabiliteId,
    getSelectedComptabilite:
      (state, getters) => getters.getComptabiliteById(getters.selectedComptabiliteId),
    exercicesComptable: (state) => state.data.exercicesComptable,
    getExerciceComptableById:
      (state) => (id:number) => {
        if (!state.data.exercicesComptable || !Array.isArray(state.data.exercicesComptable)) {
          console.error('exercicesComptable is not defined or not an array');
          return undefined;
        }

        return state.data.exercicesComptable.find((exc) => exc.id === Number(id));
      },
    selectedExerciceComptableId: (state) => state.all.selectedExerciceComptableId,
    getSelectedExerciceComptable:
      (state, getters) => getters.getExerciceComptableById(getters.selectedExerciceComptableId),
    bilan: (state) => state.data.bilan,
    message: (state) => state.all.messages,
    getSelectedExerciceComptableTVAValues:
      (state, getters) => [
        { label: 'Tva Normal', value: getters.getSelectedExerciceComptable.VAT },
        { label: 'Tva Réduit', value: getters.getSelectedExerciceComptable.VATReduced },
        { label: 'Tva Special', value: getters.getSelectedExerciceComptable.VATSpecial },
        { label: 'Pas de TVA', value: 0 },
        { label: '100%', value: 1 },
      ],
  },
  mutations: {
    addMessage(state, payload): void {
      state.all.messages = payload;
    },
    setUser(state, payload): void {
      state.user = payload.authenticatedUser;
    },
    setLoading(state, payload) {
      state.all.loading = payload.loading;
    },
    setBackgroundLoading(state, payload) {
      state.all.backgroundLoading = payload.backgroundLoading;
    },
    setComptes(state, payload) {
      state.data.comptes = payload.comptes;
    },
    setCompte(state, payload) {
      state.data.comptes[payload.position] = payload.compte;
    },
    addCompte(state, payload) {
      state.data.comptes.push(payload.compte);
    },
    editCompte(state, payload) {
      const index = state.data.comptes
        .findIndex((cpt) => cpt.id === payload.compte.id);
      state.data.comptes[index] = payload.compte;
    },
    removeCompte(state, payload) {
      const index = state.data.comptes
        .findIndex((cpt) => cpt.id === payload.compte.id);
      state.data.comptes.splice(index, 1);
    },
    setTypesFichier(state, payload) {
      state.data.typesFichier = payload.typesFichier;
    },
    setFichiers(state, payload) {
      state.data.fichiers = payload.fichiers;
    },
    setStandaloneFichiers(state, payload) {
      state.data.standaloneFiles = payload.response;
    },
    setEcritures(state, payload) {
      state.data.ecritures = payload.ecritures;
    },
    setEcriture(state, payload) {
      state.data.ecritures[payload.position] = payload.ecriture;
    },
    setExercicesComptables(state, payload) {
      state.data.exercicesComptable = payload.exercicesComptable;
    },
    setExerciceComptable(state, payload) {
      state.data.exercicesComptable[payload.position] = payload.exercicecomptable;
    },
    addExerciceComptable(state, payload) {
      state.data.exercicesComptable.push(payload.exercicecomptable);
    },
    editExerciceComptable(state, payload) {
      const index = state.data.exercicesComptable
        .findIndex((exc) => exc.id === payload.exercicecomptable.id);
      state.data.exercicesComptable[index] = payload.exercicecomptable;
    },
    removeExerciceComptablee(state, payload) {
      const index = state.data.exercicesComptable
        .findIndex((exc) => exc.id === payload.exercicecomptable.id);
      state.data.exercicesComptable.splice(index, 1);
    },
    setComptabilites(state, payload) {
      state.data.comptabilites = payload.comptabilites;
    },
    setSelectedComptabiliteId(state, payload) {
      state.all.selectedComptabiliteId = payload.comptabiliteId;
    },
    setSelectedExerciceComptabiliteId(state, payload) {
      state.all.selectedExerciceComptableId = payload.exerciceComptableId;
    },
    setBilan(state, payload) {
      state.data.bilan = payload.bilan;
    },
  },
  actions: {
    login({ commit }, user) {
      const requestData:any = JSON.stringify({
        username: user.username,
        password: user.password,
      });

      commit('setLoading', { loading: true });

      return axios.post('/authenticate', requestData)
        .then((response) => {
          if (response.status === 200
              && response.data.token !== undefined
              && response.data.token !== '') {
            const authenticatedUser = {
              ID: response.data.id,
              username: response.data.username,
              expiration: new Date(response.data.expiration),
              admin: Boolean(response.data.admin),
              token: response.data.token,
            } as User;
            commit('setUser', { authenticatedUser });
            commit(
              'addMessage',
              {
                message: 'Connexion réussie',
                type: 'success',
              },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de connexion: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setLoading', { loading: false });
        });
    },
    updateAllComptes({ commit, getters }) {
      // Update Comptes Data
      console.log('get all comptes');

      commit('setBackgroundLoading', { backgroundLoading: true });

      return axios.get(`/comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/compte`, {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          const comptes:Compte[] = response.data.map((d:any) => ({
            id: d.id,
            libelle: d.libelle,
          } as Compte));
          commit('setComptes', { comptes });
          commit(
            'addMessage',
            {
              message: 'Récupération des comptes',
              type: 'success',
            },
          );
        }
      })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de récupération de la liste des comptes: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    addCompte({ commit, getters, dispatch }, compte) {
      // commit('addCompte', { compte });

      commit('setBackgroundLoading', { backgroundLoading: true });

      const bodyParameter = compte;
      const configHeader = {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      };

      return axios.post(
        `/comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/compte`,
        bodyParameter,
        configHeader,
      )
        .then((response) => {
          if (response.status === 201) {
            console.log(response.data);
            commit(
              'addMessage',
              {
                message: 'Ajout du compte',
                type: 'success',
              },
            );
            dispatch('updateAllComptes');
          } else {
            commit(
              'addMessage',
              {
                message: `Échec de l'ajout d'un compte: ${response}`,
                type: 'warning',
              },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de l'ajout d'un compte: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    editCompte({ commit, getters, dispatch }, compte: Compte) {
      commit('setBackgroundLoading', { backgroundLoading: true });

      const bodyParameter = compte;
      const configHeader = {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      };

      return axios.put(
        `/comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/compte/${compte.id}`,
        bodyParameter,
        configHeader,
      )
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            commit(
              'addMessage',
              {
                message: 'Édition du compte',
                type: 'success',
              },
            );
            dispatch('updateAllComptes');
          } else {
            commit(
              'addMessage',
              {
                message: `Échec de l'édition d'un compte: ${response}`,
                type: 'warning',
              },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de l'édition d'un compte: ${error}`,
              type: 'danger',
            },
          );
          // TODO revert change
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    removeCompte({ commit, getters, dispatch }, compte) {
      commit('setBackgroundLoading', { backgroundLoading: true });

      const configHeader = {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      };

      return axios.delete(
        `/comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/compte/${compte.id}`,
        configHeader,
      )
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            commit(
              'addMessage',
              {
                message: 'Suppression du compte',
                type: 'success',
              },
            );
            dispatch('updateAllComptes');
          } else {
            commit(
              'addMessage',
              {
                message: `Échec de la suppression d'un compte: ${response}`,
                type: 'warning',
              },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de la suppression d'un compte: ${error}`,
              type: 'danger',
            },
          );
          // TODO revert change
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    updateAllTypesFichier({ commit, getters }) {
      // Update Type Fichier Data
      console.log('get all types fichiers');

      commit('setBackgroundLoading', { backgroundLoading: true });

      return axios.get(`/comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/typefichier`, {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          const typesFichier:TypeFichier[] = response.data.map((d:any) => ({
            id: d.id,
            libelle: d.libelle,
          } as TypeFichier));
          commit('setTypesFichier', { typesFichier });
          commit(
            'addMessage',
            {
              message: 'Récupération des types de fichiers',
              type: 'success',
            },
          );
        }
      })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de récupération de la liste des types de fichiers: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    addTypeFichier({ commit, getters, dispatch }, typeFichier: TypeFichier) {
      // commit('addCompte', { compte });

      commit('setBackgroundLoading', { backgroundLoading: true });

      const bodyParameter = typeFichier;
      const configHeader = {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      };

      return axios.post(
        `/comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/typefichier`,
        bodyParameter,
        configHeader,
      )
        .then((response) => {
          if (response.status === 201) {
            console.log(response.data);
            commit(
              'addMessage',
              {
                message: 'Ajout du type de fichier',
                type: 'success',
              },
            );
            dispatch('updateAllTypesFichier');
          } else {
            commit(
              'addMessage',
              {
                message: `Échec de l'ajout d'un type de fichier: ${response}`,
                type: 'warning',
              },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de l'ajout d'un type de fichier: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    editTypeFichier({ commit, getters, dispatch }, typeFichier: TypeFichier) {
      commit('setBackgroundLoading', { backgroundLoading: true });

      const bodyParameter = typeFichier;
      const configHeader = {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      };

      return axios.put(
        `/comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/typefichier/${typeFichier.id}`,
        bodyParameter,
        configHeader,
      )
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            commit(
              'addMessage',
              {
                message: 'Édition du type de fichier',
                type: 'success',
              },
            );
            dispatch('updateAllTypesFichier');
          } else {
            commit(
              'addMessage',
              {
                message: `Échec de l'édition d'un type de fichier: ${response}`,
                type: 'warning',
              },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de l'édition d'un type de fichier: ${error}`,
              type: 'danger',
            },
          );
          // TODO revert change
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    removeTypeFichier({ commit, getters, dispatch }, typeFichier: TypeFichier) {
      commit('setBackgroundLoading', { backgroundLoading: true });

      const configHeader = {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      };

      return axios.delete(
        `/comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/typefichier/${typeFichier.id}`,
        configHeader,
      )
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            commit(
              'addMessage',
              {
                message: 'Suppression du type de fichier',
                type: 'success',
              },
            );
            dispatch('updateAllTypesFichier');
          } else {
            commit(
              'addMessage',
              {
                message: `Échec de la suppression d'un type de fichier: ${response}`,
                type: 'warning',
              },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de la suppression d'un type de fichier: ${error}`,
              type: 'danger',
            },
          );
          // TODO revert change
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    updateStandaloneFichier({ commit, getters }) {
      // Update Type Fichier Data
      console.log('get standalone fichiers');

      commit('setBackgroundLoading', { backgroundLoading: true });

      return getStandaloneFichiers().then((response) => {
        if (response !== undefined) {
          commit('setStandaloneFichiers', { response });
          commit(
            'addMessage',
            {
              message: 'Récupération des fichiers orphelins',
              type: 'success',
            },
          );
        }
      })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de récupération des fichiers orphelins: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    updateAllFichiers({ commit, getters }) {
      // Update Comptes Data
      commit('setBackgroundLoading', { backgroundLoading: true });

      return axios.get(`/comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/fichier`, {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          const fichiers: Fichier[] = response.data.map((d:any) => ({
            id: d.id,
            nom: d.nom,
            mime: d.mime,
            data: d.data,
            type: getters.getTypesFichierById(d.type),
            createdAt: new Date(d.createdAt),
            createdBy: d.createdBy,
          } as Fichier));
          commit('setFichiers', { fichiers });
          commit(
            'addMessage',
            {
              message: 'Récupération des fichiers',
              type: 'success',
            },
          );
        }
      })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de récupération de la liste des fichiers: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    addFichier({ commit, getters, dispatch }, fichier: Fichier) {
      // commit('addCompte', { compte });

      commit('setBackgroundLoading', { backgroundLoading: true });

      const bodyParameter = {
        nom: fichier.nom,
        mime: fichier.mime,
        data: fichier.data,
        type: fichier.type.id,
      };
      const configHeader = {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      };

      return axios.post(
        `/comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/fichier`,
        bodyParameter,
        configHeader,
      )
        .then((response) => {
          if (response.status === 201) {
            console.log(response.data);
            commit(
              'addMessage',
              {
                message: 'Ajout du fichier',
                type: 'success',
              },
            );
            dispatch('updateAllFichiers');
          } else {
            commit(
              'addMessage',
              {
                message: `Échec de l'ajout d'un fichier: ${response}`,
                type: 'warning',
              },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de l'ajout d'un fichier: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    editFichier({ commit, getters, dispatch }, fichier: Fichier) {
      commit('setBackgroundLoading', { backgroundLoading: true });

      const bodyParameter = fichier;
      const configHeader = {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      };

      return axios.put(
        `/comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/fichier/${fichier.id}`,
        bodyParameter,
        configHeader,
      )
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            commit(
              'addMessage',
              {
                message: 'Édition du fichier',
                type: 'success',
              },
            );
            dispatch('updateAllFichiers');
          } else {
            commit(
              'addMessage',
              {
                message: `Échec de l'édition d'un fichier: ${response}`,
                type: 'warning',
              },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de l'édition d'un fichier: ${error}`,
              type: 'danger',
            },
          );
          // TODO revert change
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    removeFichier({ commit, getters, dispatch }, fichier: Fichier) {
      commit('setBackgroundLoading', { backgroundLoading: true });

      const configHeader = {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      };

      return axios.delete(
        `/comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/fichier/${fichier.id}`,
        configHeader,
      )
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            commit(
              'addMessage',
              {
                message: 'Suppression du fichier',
                type: 'success',
              },
            );
            dispatch('updateAllFichiers');
          } else {
            commit(
              'addMessage',
              {
                message: `Échec de la suppression d'un fichier: ${response}`,
                type: 'warning',
              },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de la suppression d'un fichier: ${error}`,
              type: 'danger',
            },
          );
          // TODO revert change
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    updateAllEcritures({ commit, getters }) {
      // Update Comptes Data
      commit('setBackgroundLoading', { backgroundLoading: true });

      console.log(`get ecritures on /comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/ecriture`);

      return axios.get(`/comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/ecriture`, {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          const ecritures: Ecriture[] = response.data.map((d:any) => {
            if (d.fichier === null) {
              return {
                ID: d.id,
                date: new Date(d.date),
                debit: getters.getCompteById(d.debit),
                credit: getters.getCompteById(d.credit),
                libelle: d.libelle,
                montant: d.montant,
                createdAt: new Date(d.createdAt),
                updatedAt: new Date(d.updatedAt),
              } as Ecriture;
            }
            return {
              ID: d.id,
              date: new Date(d.date),
              debit: getters.getCompteById(d.debit),
              credit: getters.getCompteById(d.credit),
              libelle: d.libelle,
              montant: d.montant,
              createdAt: new Date(d.createdAt),
              updatedAt: new Date(d.updatedAt),
              fichier: { id: d.fichier } as Fichier,
            } as Ecriture;
          });
          commit('setEcritures', { ecritures });
          commit(
            'addMessage',
            {
              message: 'Récupération des écritures',
              type: 'success',
            },
          );
        }
      })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de récupération de la liste des écritures: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    addEcriture({ commit, getters, dispatch }, ecriture: {
      ecriture: Ecriture,
      fichier?: Fichier,
    }) {
      // Add a new Ensemble to vuex and execute the request to the API
      commit('setBackgroundLoading', { backgroundLoading: true });

      const parsedEcritureObj: Ecriture = ecriture.ecriture;
      const ecritureDate = new Date(parsedEcritureObj.date);

      const timezoneOffset = ecritureDate.getTimezoneOffset();
      const correctedDate = new Date(ecritureDate.getTime() - 60 * timezoneOffset * 1e3).toISOString().split('T')[0];

      let bodyParameter = {};
      if (ecriture.fichier !== undefined) {
        bodyParameter = {
          date: correctedDate,
          credit: parsedEcritureObj.credit.id,
          debit: parsedEcritureObj.debit.id,
          libelle: parsedEcritureObj.libelle,
          montant: parsedEcritureObj.montant,
          fichier: {
            id: ecriture.fichier.id,
            nom: ecriture.fichier.nom,
            mime: ecriture.fichier.mime,
            data: ecriture.fichier.data,
            type: ecriture.fichier.type.id,
          },
        };
      } else {
        bodyParameter = {
          date: correctedDate,
          credit: parsedEcritureObj.credit.id,
          debit: parsedEcritureObj.debit.id,
          libelle: parsedEcritureObj.libelle,
          montant: parsedEcritureObj.montant,
        };
      }

      const configHeader = {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      };

      return axios.post(
        `/comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/ecriture`,
        bodyParameter,
        configHeader,
      )
        .then((response) => {
          if (response.status === 201) {
            console.log(response.data);
            commit(
              'addMessage',
              {
                message: 'Ajout d\'une écriture',
                type: 'success',
              },
            );
          } else {
            commit(
              'addMessage',
              {
                message: `${response.status}: Échec de l'ajout d'une écriture: ${response.data}`,
                type: 'warning',
              },
            );
          }
          return dispatch('updateAllEcritures');
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de l'ajout d'une écriture: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    addEcritureTVA({ commit, dispatch, getters }, ecriture: {
      ecriture: Ecriture,
      montantTVA: number,
      tauxTVA: number,
      fichier?: Fichier,
    }) {
      // Add a new Ensemble to vuex and execute the request to the API
      commit('setBackgroundLoading', { backgroundLoading: true });

      const timezoneOffset = ecriture.ecriture.date.getTimezoneOffset();
      const correctedDate = new Date(ecriture.ecriture.date.getTime() - 60 * timezoneOffset * 1e3).toISOString().split('T')[0];

      let bodyParameter = {};
      if (ecriture.fichier !== undefined) {
        bodyParameter = {
          date: correctedDate,
          credit: ecriture.ecriture.credit.id,
          debit: ecriture.ecriture.debit.id,
          libelle: ecriture.ecriture.libelle,
          montant: ecriture.ecriture.montant,
          tva: ecriture.montantTVA,
          taux: ecriture.tauxTVA,
          fichier: {
            id: ecriture.fichier.id,
            nom: ecriture.fichier.nom,
            mime: ecriture.fichier.mime,
            data: ecriture.fichier.data,
            type: ecriture.fichier.type.id,
          },
        };
      } else {
        bodyParameter = {
          date: correctedDate,
          credit: ecriture.ecriture.credit.id,
          debit: ecriture.ecriture.debit.id,
          libelle: ecriture.ecriture.libelle,
          montant: ecriture.ecriture.montant,
          tva: ecriture.montantTVA,
          taux: ecriture.tauxTVA,
        };
      }
      const configHeader = {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      };

      console.log(bodyParameter);

      return axios.post(
        `/comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/ecrituretva`,
        bodyParameter,
        configHeader,
      )
        .then((response) => {
          if (response.status === 201) {
            commit(
              'addMessage',
              {
                message: 'Ajout d\'une écriture',
                type: 'success',
              },
            );
          } else {
            console.log(response.data);
            commit(
              'addMessage',
              {
                message: `Échec de l'ajout d'une écriture: ${response}`,
                type: 'warning',
              },
            );
          }
          // updateAllEcritures
          return dispatch('updateAllEcritures');
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de l'ajout d'une écriture: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    editEcriture({ commit, dispatch, getters }, ecriture: {
      ecriture: Ecriture,
      fichier?: Fichier,
    }) {
      commit('setBackgroundLoading', { backgroundLoading: true });

      const parsedEcritureObj: Ecriture = ecriture.ecriture;
      const ecritureDate = new Date(parsedEcritureObj.date);

      const timezoneOffset = ecritureDate.getTimezoneOffset();
      const correctedDate = new Date(ecritureDate.getTime() - 60 * timezoneOffset * 1e3).toISOString().split('T')[0];

      let bodyParameter = {};
      if (ecriture.fichier !== undefined) {
        bodyParameter = {
          id: parsedEcritureObj.ID,
          date: correctedDate,
          credit: parsedEcritureObj.credit.id,
          debit: parsedEcritureObj.debit.id,
          libelle: parsedEcritureObj.libelle,
          montant: parsedEcritureObj.montant,
          fichier: {
            id: ecriture.fichier.id,
            nom: ecriture.fichier.nom,
            mime: ecriture.fichier.mime,
            data: ecriture.fichier.data,
            type: ecriture.fichier.type.id,
          },
        };
      } else {
        bodyParameter = {
          date: correctedDate,
          credit: parsedEcritureObj.credit.id,
          debit: parsedEcritureObj.debit.id,
          libelle: parsedEcritureObj.libelle,
          montant: parsedEcritureObj.montant,
        };
      }
      const configHeader = {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      };

      console.log(` PUT ON /comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/ecriture/${parsedEcritureObj.ID}`);

      return axios.put(
        `/comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/ecriture/${parsedEcritureObj.ID}`,
        bodyParameter,
        configHeader,
      )
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            commit(
              'addMessage',
              {
                message: 'Édition écriture',
                type: 'success',
              },
            );
          } else {
            commit(
              'addMessage',
              {
                message: `Échec de l'édition d'une écriture: ${response}`,
                type: 'warning',
              },
            );
          }
          return dispatch('updateAllEcritures');
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de l'édition d'une écriture: ${error}`,
              type: 'danger',
            },
          );
          // TODO revert change
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    removeEcriture({ commit, getters, dispatch }, ecriture) {
      commit('setBackgroundLoading', { backgroundLoading: true });

      const configHeader = {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      };

      return axios.delete(
        `/comptabilite/${getters.selectedComptabiliteId}/ecriture/${ecriture.id}`,
        configHeader,
      )
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            commit(
              'addMessage',
              {
                message: 'Écriture supprimée',
                type: 'success',
              },
            );
            dispatch('updateAllComptes');
          } else {
            commit(
              'addMessage',
              {
                message: `Échec de la suppression d'une écriture: ${response}`,
                type: 'warning',
              },
            );
          }
          return dispatch('updateAllEcritures');
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de la suppression d'une écriture: ${error}`,
              type: 'danger',
            },
          );
          return dispatch('updateAllEcritures');
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    removeEcritureTVA({ commit, getters, dispatch }, ecriture) {
      commit('setBackgroundLoading', { backgroundLoading: true });

      const configHeader = {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      };

      return axios.delete(
        `/comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/ecrituretva/${ecriture.id}`,
        configHeader,
      )
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            commit(
              'addMessage',
              {
                message: 'Écriture supprimée',
                type: 'success',
              },
            );
            dispatch('updateAllComptes');
          } else {
            commit(
              'addMessage',
              {
                message: `Échec de la suppression d'une écriture: ${response}`,
                type: 'warning',
              },
            );
          }
          return dispatch('updateAllEcritures');
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de la suppression d'une écriture: ${error}`,
              type: 'danger',
            },
          );
          return dispatch('updateAllEcritures');
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    updateAllExercicesComptable({ commit, getters }) {
      // Update Comptes Data
      commit('setBackgroundLoading', { backgroundLoading: true });

      return axios.get(`/comptabilite/${getters.selectedComptabiliteId}/exerciceComptable`, {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          const exercicesComptable: ExerciceComptable[] = response.data.map((d:any) => ({
            id: d.id,
            start: new Date(d.debut),
            end: new Date(d.fin),
            locked: d.verrouille === '1',
            lockedDate: new Date(d.dateVerrouillage),
            VAT: Number(d.TauxTVANormal),
            VATReduced: Number(d.TauxTVAReduit),
            VATSpecial: Number(d.TauxTVASpecial),
            lockedQ1: d.verrouillageQ1 === '1',
            lockedQ1Date: new Date(d.dateVerrouillageQ1),
            lockedQ2: d.verrouillageQ2 === '1',
            lockedQ2Date: new Date(d.dateVerrouillageQ2),
            lockedQ3: d.verrouillageQ3 === '1',
            lockedQ3Date: new Date(d.dateVerrouillageQ3),
            lockedQ4: d.verrouillageQ4 === '1',
            lockedQ4Date: new Date(d.dateVerrouillageQ4),
          } as ExerciceComptable));
          console.log('Exercice comptables récupéré', exercicesComptable);
          commit('setExercicesComptables', { exercicesComptable });
          commit(
            'addMessage',
            {
              message: 'Récupération des exercices comptables',
              type: 'success',
            },
          );
          return exercicesComptable;
        }
        return new Array<ExerciceComptable>();
      })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de récupération de la liste des exercices comptable: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    addExerciceComptable({ commit, getters }, exercicecomptable) {
      // Add a new Ensemble to vuex and execute the request to the API
      commit('addExerciceComptable', { exercicecomptable });
      const newExerciceComptablePos = getters.ecritures.length - 1;

      commit('setBackgroundLoading', { backgroundLoading: true });

      const bodyParameter = exercicecomptable;
      const configHeader = {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      };

      return axios.post(
        `/comptabilite/${getters.selectedComptabiliteId}/exerciceComptable`,
        bodyParameter,
        configHeader,
      )
        .then((response) => {
          if (response.status === 201) {
            console.log(response.data);
            commit('setEcriture', {
              position: newExerciceComptablePos,
              ecriture: response.data,
            });
            commit(
              'addMessage',
              {
                message: 'Ajout d\'un exercice comptable',
                type: 'success',
              },
            );
          } else {
            commit(
              'addMessage',
              {
                message: `Échec de l'ajout d'un exercice comptable: ${response}`,
                type: 'warning',
              },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de l'ajout d'un exercice comptable: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    editExerciceComptable({ commit, getters }, exercicecomptable) {
      // edit an Ensemble to vuex and execute the request to the API
      commit('editExerciceComptable', { exercicecomptable });

      commit('setBackgroundLoading', { backgroundLoading: true });

      const bodyParameter = exercicecomptable;
      const configHeader = {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      };

      return axios.put(
        `/comptabilite/${getters.selectedComptabiliteId}/exerciceComptable/${exercicecomptable.id}`,
        bodyParameter,
        configHeader,
      )
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            commit(
              'addMessage',
              {
                message: 'Édition de exercice comptable',
                type: 'success',
              },
            );
          } else {
            commit(
              'addMessage',
              {
                message: `Échec de l'édition d'un exercice comptable: ${response}`,
                type: 'warning',
              },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de l'édition d'un exercice comptable: ${error}`,
              type: 'danger',
            },
          );
          // TODO revert change
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    removeExerciceComptable({ commit, getters }, exercicecomptable) {
      // Remove an Ensemble to vuex and execute the request to the API
      commit('removeExerciceComptablee', { exercicecomptable });

      commit('setBackgroundLoading', { backgroundLoading: true });

      const configHeader = {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      };

      return axios.delete(
        `/comptabilite/${getters.selectedComptabiliteId}/exerciceComptable/${exercicecomptable.id}`,
        configHeader,
      )
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            commit(
              'addMessage',
              {
                message: 'Suppression exercice comptable',
                type: 'success',
              },
            );
          } else {
            commit(
              'addMessage',
              {
                message: `Échec de la suppression d'un exercice comptable: ${response}`,
                type: 'warning',
              },
            );
          }
        })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de la suppression d'un exercice comptable: ${error}`,
              type: 'danger',
            },
          );
          // TODO revert change
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    updateAllComptabilites({ commit, getters }) {
      // Update Comptes Data
      commit('setBackgroundLoading', { backgroundLoading: true });

      const configHeader = {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      };
      return axios.get('/comptabilite', {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          commit('setComptabilites', { comptabilites: response.data });
          commit(
            'addMessage',
            {
              message: 'Récupération des comptabilites',
              type: 'success',
            },
          );
        }
      })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de récupération de la liste des comptabilites: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
    updateSelectedComptabilite({ commit, getters }, comptabiliteId) {
      // Update Comptes Data
      commit('setSelectedComptabiliteId', { comptabiliteId });
    },
    updateSelectedExerciceComptabilite({ commit, getters }, exerciceComptableId) {
      console.log(`selected compta is ${exerciceComptableId}`);
      // Update Comptes Data
      commit('setSelectedExerciceComptabiliteId', { exerciceComptableId });
    },
    updateBilan({ commit, getters }, range) {
      // Update Comptes Data
      commit('setBackgroundLoading', { backgroundLoading: true });

      const configHeader = {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      };
      console.log(`GET /comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/bilan/${range.start}/${range.end}`);
      return axios.get(`/comptabilite/${getters.selectedComptabiliteId}/exercice/${getters.selectedExerciceComptableId}/bilan/${range.start}/${range.end}`, {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          commit('setBilan', { bilan: response.data });
          commit(
            'addMessage',
            {
              message: 'Récupération du bilan',
              type: 'success',
            },
          );
        }
      })
        .catch((error) => {
          console.log(error);
          commit(
            'addMessage',
            {
              message: `Échec de récupération du bilan: ${error}`,
              type: 'danger',
            },
          );
        })
        .finally(() => {
          commit('setBackgroundLoading', { backgroundLoading: false });
        });
    },
  },
  modules: {
  },
});
